import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PaymentChannelProvider extends HttpRequest {
  getPaymentChannel () {
    this.setHeader(getAuthToken())
    return this.get('/payment-channel')
  }

  deletePaymentChannel (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/payment-channel?id=${id}`)
  }

  createPaymentChannel (payload) {
    this.setHeader(getAuthToken())
    return this.post('/payment-channel', payload)
  }

  updatePaymentChannel (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/payment-channel/${id}`, payload)
  }
}

export default PaymentChannelProvider
