<template>
  <div id="paymentchannel-setting">
    <v-data-table
      :headers="header"
      :items="paymentData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :height="530"
      :search="inputSearch"
      class="elevation-1 mb-5"
      hide-default-footer
      @page-count="pageCount = $event">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Payment Channel
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-text-field
            ref="searchTextField"
            v-model="inputSearch"
            append-icon="mdi-magnify"
            class="mb-2 mr-5 search-text-field"
            label="Search"
            single-line
            hide-details />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col>
                        <gw-drop-file
                          v-model="formData.logo"
                          :show-button="false"
                          s3-path="/payment-logo"
                          label="Upload Image Here" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.name"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Name" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-select
                          v-model="formData.channel"
                          :rules="textFieldRules"
                          :items="channels"
                          :readonly="modalLoading"
                          label="Channel"
                          item-text="name" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-select
                          v-model="formData.type"
                          :rules="textFieldRules"
                          :items="paymentChannelType"
                          :readonly="modalLoading"
                          label="Type"
                          item-text="name" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #[`item.logo`]="{ item }">
        <img
          :src="imgSrcCompute(item.logo)"
          width="96"
          :alt="item.type">
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removePaymentChannel(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PaymentChannelProvider from '@/resources/PaymentChannelProvider'

const PaymentChannelService = new PaymentChannelProvider()

export default {
  data () {
    return {
      inputSearch: '',
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
      {
          text: 'Logo',
          value: 'logo',
          width: 200
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'end'
        }
      ],
      paymentData: [],
      isEdited: false,
      formData: {},
      textFieldRules: {
        required: true
      },
      getting: false,
      modalLoading: false,
      isFocus: false,
      channels: [
        {
          name: 'WEB',
          value: 'web'
        },
        {
          name: 'POS',
          value: 'pos'
        }
      ],
      paymentChannelType: [
        {
          name: 'Cash',
          value: 'cash'
        },
        {
          name: 'Credit',
          value: 'credit'
        },
        {
          name: '2c2p',
          value: '2c2p'
        },
        {
          name: 'Bank',
          value: 'bank'
        },
        {
          name: 'Other',
          value: 'other'
        }
      ]
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  created () {
    this.getPaymentChannel()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    imgSrcCompute (src) {
      return `${src}?fm=jpg&w=400` || 'https://via.placeholder.com/150'
    },
    setPageCount (event) {
      this.pageCount = event
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    async getPaymentChannel () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await PaymentChannelService.getPaymentChannel()
          this.paymentData = data
        } catch (error) {
          console.error('getPaymentChannel: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {}
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        name: item.name,
        channel: item.channel,
        type: item.type
      }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          if (this.isEdited) {
            isSuscess = await this.updatePaymentChannel(this.formData)
            message = 'updated'
          } else {
            isSuscess = await this.createPaymentChannel(this.formData)
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          this.closeFormModal()
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.Payment ${message}`),
              type: 'success'
            })
            this.getPaymentChannel()
          }
        }
      }
      this.modalLoading = false
    },
    async createPaymentChannel (item) {
      const { data } = await PaymentChannelService.createPaymentChannel(item)
      return !!data.id
    },
    async updatePaymentChannel (item) {
      const { data } = await PaymentChannelService.updatePaymentChannel(item.id, item)
      return !!data.id
    },
    async removePaymentChannel (item) {
      this.setModal({
        value: true,
        title: 'RemovePayment',
        message: 'Do you want to remove the payment',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemovePaymentChannel(item.id)
      })
    },
    async confirmRemovePaymentChannel (id) {
      try {
        const { data } = await PaymentChannelService.deletePaymentChannel(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.Payment removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.paymentData.length < 2) {
            this.options.page--
          } else {
            this.getPaymentChannel()
          }
        } else {
          const error = {
            code: 400,
            message: 'Payment ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemovePaymentChannel', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
